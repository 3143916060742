import moment from 'moment-timezone';

export function horaLimitesCalendarPrimevue() {
    const INICIO_HORARIO_COMERCIAL = [5, 0, 0, 0];
    const FIM_HORARIO_COMERCIAL = [19, 59, 0, 0];

    const min = new Date();
    min.setHours(...INICIO_HORARIO_COMERCIAL);
    const max = new Date();
    max.setHours(...FIM_HORARIO_COMERCIAL);

    return {
        min,
        max
    };
}

export function horaLimites() {
    const INICIO_HORARIO_COMERCIAL = '06:00';
    const FIM_HORARIO_COMERCIAL = '19:01';
    const format = 'HH:mm';

    const inicioValido = moment(INICIO_HORARIO_COMERCIAL, format);
    const fimValido = moment(FIM_HORARIO_COMERCIAL, format);

    return { inicioValido, fimValido, format };
}

export function isHoraInHorarioComercial(hora) {
    const { format, inicioValido, fimValido } = horaLimites();
    const horaDesejada = moment(hora, format);

    if (horaDesejada.isBetween(inicioValido, fimValido)) {
        return true;
    }
}
